<script setup lang="ts">
import type { NuxtError } from '#app';
const localePath = useLocalePath();

defineProps({
  error: {
    type: Object as () => NuxtError,
    required: true,
  },
});

const handleError = () => clearError({ redirect: localePath('/') });
</script>

<template>
  <div>
    <h2>{{ error.statusCode }} {{ error.message }}</h2>
    <p v-if="error?.statusMessage && error.statusMessage !== error.message">
      {{ error.statusMessage }}
    </p>

    <button @click="handleError">Clear errors</button>

    <pre
      >{{ error }}
    </pre>
  </div>
</template>

import revive_payload_client_YTGlmQWWT5 from "/builds/product/whitelabel/whitelabel/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/builds/product/whitelabel/whitelabel/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/builds/product/whitelabel/whitelabel/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/builds/product/whitelabel/whitelabel/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/builds/product/whitelabel/whitelabel/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/builds/product/whitelabel/whitelabel/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xKuw1km5sV from "/builds/product/whitelabel/whitelabel/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/product/whitelabel/whitelabel/apps/portal/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/builds/product/whitelabel/whitelabel/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_l138bYv0gX from "/builds/product/whitelabel/whitelabel/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_8brYH9QEed from "/builds/product/whitelabel/whitelabel/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import i18n_dOTnCc6TUQ from "/builds/product/whitelabel/whitelabel/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chartjs_f8QvQjwUpc from "/builds/product/whitelabel/whitelabel/plugins/chartjs.ts";
import clickOutside_c6bmNiXMM0 from "/builds/product/whitelabel/whitelabel/plugins/clickOutside.ts";
import focusFirst_client_NGxo1Ubh2u from "/builds/product/whitelabel/whitelabel/plugins/focusFirst.client.ts";
import imgproxy_jOiRxteQL0 from "/builds/product/whitelabel/whitelabel/plugins/imgproxy.ts";
import pdfreactor_ZAiQn8NITr from "/builds/product/whitelabel/whitelabel/plugins/pdfreactor.ts";
import urql_pBeWv2gdjX from "/builds/product/whitelabel/whitelabel/plugins/urql.ts";
import vueDatepicker_LMuXIetOJ1 from "/builds/product/whitelabel/whitelabel/plugins/vueDatepicker.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_l138bYv0gX,
  plugin_8brYH9QEed,
  i18n_dOTnCc6TUQ,
  chartjs_f8QvQjwUpc,
  clickOutside_c6bmNiXMM0,
  focusFirst_client_NGxo1Ubh2u,
  imgproxy_jOiRxteQL0,
  pdfreactor_ZAiQn8NITr,
  urql_pBeWv2gdjX,
  vueDatepicker_LMuXIetOJ1
]
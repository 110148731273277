import { defineNuxtPlugin } from '#app';
import { onClickOutside } from '@vueuse/core';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', {
    beforeMount(el, binding) {
      let ignoreElements = [];
      let handler;

      if (typeof binding.value === 'function') {
        handler = binding.value;
      } else if (typeof binding.value === 'object') {
        ignoreElements = binding.value.ignore || [];
        handler = binding.value.handler;
      }

      el.__clickOutsideHandler__ = onClickOutside(el, handler, {
        ignore: ignoreElements,
      });
    },
    unmounted(el) {
      // Remove the event listener using the stored reference
      if (el.__clickOutsideHandler__) {
        el.__clickOutsideHandler__();
        delete el.__clickOutsideHandler__;
      }
    },
  });
});

import type { BaseInstanceConfig } from '../models/BaseInstanceConfig';

export const CONFIG: Omit<BaseInstanceConfig, 'instanceId'> = {
  graphqlEndpoint: `https://content-delivery.imxplatform.de/demo/imxplatform`,
  graphqlBearerToken:
    '169cec0841d9eb399136d77f4aa9bcffc435a48d5128b8ebbd776b1faecc34d1', // TODO create an actual key (this is a global key which should NOT be used!)
  defaultWidgetId: 1,
  enabledLanguages: ['de', 'en'],
  brandingAlias: 'base',
  footerContact: {
    name: 'infomax websolutions GmbH',
    street: 'Aichfeld 2',
    zipcode: '83224',
    city: 'Grassau',
    email: 'support@infomax-it.de',
  },
  defaults: {},
  map: {
    defaultCoords: {
      // grassau
      latitude: 47.7821689,
      longitude: 12.4629075,
    },
  },
};

const VERSION = 7;

const DEFAULT_URL = 'https://whitelabel.infomax.dev/pdf';

export type PDFreactorConfig = {
  document: string;
  clientName?: string;
  clientVersion?: number;
};

export class PDFreactor {
  private static instances: Record<string, PDFreactor> = {};
  private url: string;

  private constructor(url: string = DEFAULT_URL) {
    this.url = url.endsWith('/') ? url.slice(0, -1) : url;
  }

  public static getInstance(url: string = DEFAULT_URL): PDFreactor {
    if (!PDFreactor.instances[url]) {
      PDFreactor.instances[url] = new PDFreactor(url);
    }
    return PDFreactor.instances[url];
  }

  async convert(
    config: PDFreactorConfig,
    connectionSettings: unknown = null
  ): Promise<unknown> {
    const url = `${this.url}/convert.json${useRuntimeConfig().pdfReactorApiKey ? `?apiKey=${useRuntimeConfig().pdfReactorApiKey}` : ''}`;
    if (config) {
      config.clientName = 'TypeScript';
      config.clientVersion = VERSION;
    }

    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      'User-Agent': 'PDFreactor TypeScript API v7',
      'X-RO-User-Agent': 'PDFreactor TypeScript API v7',
    };

    if (connectionSettings?.headers) {
      Object.entries(connectionSettings.headers).forEach(([name, value]) => {
        const lcName = name.toLowerCase();
        if (!['user-agent', 'content-type', 'range'].includes(lcName)) {
          headers[name] = value;
        }
      });
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(config),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        `PDFreactor Web Service error (status: ${response.status}). ${errorResponse.error}`
      );
    }

    return await response.json();
  }

  async convertAsBinary(
    config: PDFreactorConfig,
    connectionSettings: unknown = null
  ): Promise<unknown> {
    const url = `${this.url}/convert.bin${useRuntimeConfig().pdfReactorApiKey ? `?apiKey=${useRuntimeConfig().pdfReactorApiKey}` : ''}`;
    if (config) {
      config.clientName = 'TypeScript';
      config.clientVersion = VERSION;
    }

    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      'User-Agent': 'PDFreactor TypeScript API v7',
      'X-RO-User-Agent': 'PDFreactor TypeScript API v7',
    };

    if (connectionSettings?.headers) {
      Object.entries(connectionSettings.headers).forEach(([name, value]) => {
        const lcName = name.toLowerCase();
        if (!['user-agent', 'content-type', 'range'].includes(lcName)) {
          headers[name] = value;
        }
      });
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(config),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        `PDFreactor Web Service error (status: ${response.status}). ${errorResponse.error}`
      );
    }

    return await response.arrayBuffer();
  }

  async convertAsync(
    config: PDFreactorConfig,
    connectionSettings: unknown = null
  ): Promise<string | null> {
    const url = `${this.url}/convert/async.json${useRuntimeConfig().pdfReactorApiKey ? `?apiKey=${useRuntimeConfig().pdfReactorApiKey}` : ''}`;
    if (config) {
      config.clientName = 'TypeScript';
      config.clientVersion = VERSION;
    }

    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      'User-Agent': 'PDFreactor TypeScript API v7',
      'X-RO-User-Agent': 'PDFreactor TypeScript API v7',
    };

    if (connectionSettings?.headers) {
      Object.entries(connectionSettings.headers).forEach(([name, value]) => {
        const lcName = name.toLowerCase();
        if (!['user-agent', 'content-type', 'range'].includes(lcName)) {
          headers[name] = value;
        }
      });
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(config),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        `PDFreactor Web Service error (status: ${response.status}). ${errorResponse.error}`
      );
    }

    const locationHeader = response.headers.get('Location');
    return locationHeader ? locationHeader.split('/').pop() : null;
  }

  async getProgress(
    documentId: string,
    connectionSettings: unknown = null
  ): Promise<unknown> {
    if (!documentId) {
      throw new Error('No conversion was triggered.');
    }
    const url = `${this.url}/progress/${documentId}.json${useRuntimeConfig().pdfReactorApiKey ? `?apiKey=${useRuntimeConfig().pdfReactorApiKey}` : ''}`;
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      'User-Agent': 'PDFreactor TypeScript API v7',
      'X-RO-User-Agent': 'PDFreactor TypeScript API v7',
    };

    if (connectionSettings?.headers) {
      Object.entries(connectionSettings.headers).forEach(([name, value]) => {
        const lcName = name.toLowerCase();
        if (!['user-agent', 'content-type', 'range'].includes(lcName)) {
          headers[name] = value;
        }
      });
    }

    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        `PDFreactor Web Service error (status: ${response.status}). ${errorResponse.error}`
      );
    }

    return await response.json();
  }

  public static CallbackType = {
    FINISH: 'FINISH',
    PROGRESS: 'PROGRESS',
    START: 'START',
  };

  public static Cleanup = {
    CYBERNEKO: 'CYBERNEKO',
    JTIDY: 'JTIDY',
    NONE: 'NONE',
    TAGSOUP: 'TAGSOUP',
  };

  public static ColorSpace = {
    CMYK: 'CMYK',
    RGB: 'RGB',
  };

  public static Conformance = {
    PDF: 'PDF',
    PDFA1A: 'PDFA1A',
    PDFA1A_PDFUA1: 'PDFA1A_PDFUA1',
    PDFA1B: 'PDFA1B',
    PDFA2A: 'PDFA2A',
    PDFA2A_PDFUA1: 'PDFA2A_PDFUA1',
    PDFA2B: 'PDFA2B',
    PDFA2U: 'PDFA2U',
    PDFA3A: 'PDFA3A',
    PDFA3A_PDFUA1: 'PDFA3A_PDFUA1',
    PDFA3B: 'PDFA3B',
    PDFA3U: 'PDFA3U',
    PDFUA1: 'PDFUA1',
    PDFX1A_2001: 'PDFX1A_2001',
    PDFX1A_2003: 'PDFX1A_2003',
    PDFX3_2002: 'PDFX3_2002',
    PDFX3_2003: 'PDFX3_2003',
    PDFX4: 'PDFX4',
    PDFX4P: 'PDFX4P',
  };

  public static ContentType = {
    BINARY: 'BINARY',
    BMP: 'BMP',
    GIF: 'GIF',
    HTML: 'HTML',
    JPEG: 'JPEG',
    JSON: 'JSON',
    NONE: 'NONE',
    PDF: 'PDF',
    PNG: 'PNG',
    TEXT: 'TEXT',
    TIFF: 'TIFF',
    XML: 'XML',
  };

  public static CssPropertySupport = {
    ALL: 'ALL',
    HTML: 'HTML',
    HTML_THIRD_PARTY: 'HTML_THIRD_PARTY',
    HTML_THIRD_PARTY_LENIENT: 'HTML_THIRD_PARTY_LENIENT',
  };

  public static Doctype = {
    AUTODETECT: 'AUTODETECT',
    HTML5: 'HTML5',
    XHTML: 'XHTML',
    XML: 'XML',
  };

  public static Encryption = {
    NONE: 'NONE',
    TYPE_128: 'TYPE_128',
    TYPE_40: 'TYPE_40',
  };

  public static ErrorPolicy = {
    LICENSE: 'LICENSE',
    MISSING_RESOURCE: 'MISSING_RESOURCE',
  };

  public static ExceedingContentAgainst = {
    NONE: 'NONE',
    PAGE_BORDERS: 'PAGE_BORDERS',
    PAGE_CONTENT: 'PAGE_CONTENT',
    PARENT: 'PARENT',
  };

  public static ExceedingContentAnalyze = {
    CONTENT: 'CONTENT',
    CONTENT_AND_BOXES: 'CONTENT_AND_BOXES',
    CONTENT_AND_STATIC_BOXES: 'CONTENT_AND_STATIC_BOXES',
    NONE: 'NONE',
  };

  public static HttpsMode = {
    LENIENT: 'LENIENT',
    STRICT: 'STRICT',
  };

  public static JavaScriptDebugMode = {
    EXCEPTIONS: 'EXCEPTIONS',
    FUNCTIONS: 'FUNCTIONS',
    LINES: 'LINES',
    NONE: 'NONE',
    POSITIONS: 'POSITIONS',
  };

  public static JavaScriptMode = {
    DISABLED: 'DISABLED',
    ENABLED: 'ENABLED',
    ENABLED_NO_LAYOUT: 'ENABLED_NO_LAYOUT',
    ENABLED_REAL_TIME: 'ENABLED_REAL_TIME',
    ENABLED_TIME_LAPSE: 'ENABLED_TIME_LAPSE',
  };

  public static KeystoreType = {
    JKS: 'JKS',
    PKCS12: 'PKCS12',
  };

  public static LogLevel = {
    DEBUG: 'DEBUG',
    FATAL: 'FATAL',
    INFO: 'INFO',
    NONE: 'NONE',
    PERFORMANCE: 'PERFORMANCE',
    WARN: 'WARN',
  };

  public static MediaFeature = {
    ASPECT_RATIO: 'ASPECT_RATIO',
    COLOR: 'COLOR',
    COLOR_INDEX: 'COLOR_INDEX',
    DEVICE_ASPECT_RATIO: 'DEVICE_ASPECT_RATIO',
    DEVICE_HEIGHT: 'DEVICE_HEIGHT',
    DEVICE_WIDTH: 'DEVICE_WIDTH',
    GRID: 'GRID',
    HEIGHT: 'HEIGHT',
    MONOCHROME: 'MONOCHROME',
    ORIENTATION: 'ORIENTATION',
    RESOLUTION: 'RESOLUTION',
    WIDTH: 'WIDTH',
  };

  public static MergeMode = {
    APPEND: 'APPEND',
    ARRANGE: 'ARRANGE',
    OVERLAY: 'OVERLAY',
    OVERLAY_BELOW: 'OVERLAY_BELOW',
    PREPEND: 'PREPEND',
  };

  public static OutputIntentDefaultProfile = {
    FOGRA39: 'Coated FOGRA39',
    GRACOL: 'Coated GRACoL 2006',
    IFRA: 'ISO News print 26% (IFRA)',
    JAPAN: 'Japan Color 2001 Coated',
    JAPAN_NEWSPAPER: 'Japan Color 2001 Newspaper',
    JAPAN_UNCOATED: 'Japan Color 2001 Uncoated',
    JAPAN_WEB: 'Japan Web Coated (Ad)',
    SWOP: 'US Web Coated (SWOP) v2',
    SWOP_3: 'Web Coated SWOP 2006 Grade 3 Paper',
  };

  public static OutputType = {
    BMP: 'BMP',
    GIF: 'GIF',
    JPEG: 'JPEG',
    PDF: 'PDF',
    PNG: 'PNG',
    PNG_AI: 'PNG_AI',
    PNG_TRANSPARENT: 'PNG_TRANSPARENT',
    PNG_TRANSPARENT_AI: 'PNG_TRANSPARENT_AI',
    TIFF_CCITT_1D: 'TIFF_CCITT_1D',
    TIFF_CCITT_GROUP_3: 'TIFF_CCITT_GROUP_3',
    TIFF_CCITT_GROUP_4: 'TIFF_CCITT_GROUP_4',
    TIFF_LZW: 'TIFF_LZW',
    TIFF_PACKBITS: 'TIFF_PACKBITS',
    TIFF_UNCOMPRESSED: 'TIFF_UNCOMPRESSED',
  };

  public static OverlayRepeat = {
    ALL_PAGES: 'ALL_PAGES',
    LAST_PAGE: 'LAST_PAGE',
    NONE: 'NONE',
    TRIM: 'TRIM',
  };

  public static PageOrder = {
    BOOKLET: 'BOOKLET',
    BOOKLET_RTL: 'BOOKLET_RTL',
    EVEN: 'EVEN',
    ODD: 'ODD',
    REVERSE: 'REVERSE',
  };

  public static PagesPerSheetDirection = {
    DOWN_LEFT: 'DOWN_LEFT',
    DOWN_RIGHT: 'DOWN_RIGHT',
    LEFT_DOWN: 'LEFT_DOWN',
    LEFT_UP: 'LEFT_UP',
    RIGHT_DOWN: 'RIGHT_DOWN',
    RIGHT_UP: 'RIGHT_UP',
    UP_LEFT: 'UP_LEFT',
    UP_RIGHT: 'UP_RIGHT',
  };

  public static PdfScriptTriggerEvent = {
    AFTER_PRINT: 'AFTER_PRINT',
    AFTER_SAVE: 'AFTER_SAVE',
    BEFORE_PRINT: 'BEFORE_PRINT',
    BEFORE_SAVE: 'BEFORE_SAVE',
    CLOSE: 'CLOSE',
    OPEN: 'OPEN',
  };

  public static ProcessingPreferences = {
    SAVE_MEMORY_IMAGES: 'SAVE_MEMORY_IMAGES',
  };

  public static QuirksMode = {
    DETECT: 'DETECT',
    QUIRKS: 'QUIRKS',
    STANDARDS: 'STANDARDS',
  };

  public static ResourceType = {
    ATTACHMENT: 'ATTACHMENT',
    FONT: 'FONT',
    ICC_PROFILE: 'ICC_PROFILE',
    IFRAME: 'IFRAME',
    IMAGE: 'IMAGE',
    MERGE_DOCUMENT: 'MERGE_DOCUMENT',
    OBJECT: 'OBJECT',
    RUNNING_DOCUMENT: 'RUNNING_DOCUMENT',
    SCRIPT: 'SCRIPT',
    STYLESHEET: 'STYLESHEET',
    UNKNOWN: 'UNKNOWN',
  };

  public static SigningMode = {
    SELF_SIGNED: 'SELF_SIGNED',
    VERISIGN_SIGNED: 'VERISIGN_SIGNED',
    WINCER_SIGNED: 'WINCER_SIGNED',
  };

  public static ViewerPreferences = {
    CENTER_WINDOW: 'CENTER_WINDOW',
    DIRECTION_L2R: 'DIRECTION_L2R',
    DIRECTION_R2L: 'DIRECTION_R2L',
    DISPLAY_DOC_TITLE: 'DISPLAY_DOC_TITLE',
    DUPLEX_FLIP_LONG_EDGE: 'DUPLEX_FLIP_LONG_EDGE',
    DUPLEX_FLIP_SHORT_EDGE: 'DUPLEX_FLIP_SHORT_EDGE',
    DUPLEX_SIMPLEX: 'DUPLEX_SIMPLEX',
    FIT_WINDOW: 'FIT_WINDOW',
    HIDE_MENUBAR: 'HIDE_MENUBAR',
    HIDE_TOOLBAR: 'HIDE_TOOLBAR',
    HIDE_WINDOW_UI: 'HIDE_WINDOW_UI',
    NON_FULLSCREEN_PAGE_MODE_USE_NONE: 'NON_FULLSCREEN_PAGE_MODE_USE_NONE',
    NON_FULLSCREEN_PAGE_MODE_USE_OC: 'NON_FULLSCREEN_PAGE_MODE_USE_OC',
    NON_FULLSCREEN_PAGE_MODE_USE_OUTLINES:
      'NON_FULLSCREEN_PAGE_MODE_USE_OUTLINES',
    NON_FULLSCREEN_PAGE_MODE_USE_THUMBS: 'NON_FULLSCREEN_PAGE_MODE_USE_THUMBS',
    PAGE_LAYOUT_ONE_COLUMN: 'PAGE_LAYOUT_ONE_COLUMN',
    PAGE_LAYOUT_SINGLE_PAGE: 'PAGE_LAYOUT_SINGLE_PAGE',
    PAGE_LAYOUT_TWO_COLUMN_LEFT: 'PAGE_LAYOUT_TWO_COLUMN_LEFT',
    PAGE_LAYOUT_TWO_COLUMN_RIGHT: 'PAGE_LAYOUT_TWO_COLUMN_RIGHT',
    PAGE_LAYOUT_TWO_PAGE_LEFT: 'PAGE_LAYOUT_TWO_PAGE_LEFT',
    PAGE_LAYOUT_TWO_PAGE_RIGHT: 'PAGE_LAYOUT_TWO_PAGE_RIGHT',
    PAGE_MODE_FULLSCREEN: 'PAGE_MODE_FULLSCREEN',
    PAGE_MODE_USE_ATTACHMENTS: 'PAGE_MODE_USE_ATTACHMENTS',
    PAGE_MODE_USE_NONE: 'PAGE_MODE_USE_NONE',
    PAGE_MODE_USE_OC: 'PAGE_MODE_USE_OC',
    PAGE_MODE_USE_OUTLINES: 'PAGE_MODE_USE_OUTLINES',
    PAGE_MODE_USE_THUMBS: 'PAGE_MODE_USE_THUMBS',
    PICKTRAYBYPDFSIZE_FALSE: 'PICKTRAYBYPDFSIZE_FALSE',
    PICKTRAYBYPDFSIZE_TRUE: 'PICKTRAYBYPDFSIZE_TRUE',
    PRINTSCALING_APPDEFAULT: 'PRINTSCALING_APPDEFAULT',
    PRINTSCALING_NONE: 'PRINTSCALING_NONE',
  };

  public static XmpPriority = {
    HIGH: 'HIGH',
    LOW: 'LOW',
    NONE: 'NONE',
  };
}

import { createImageProxyService } from '../utils/createImageProxyService';
import { ImgProxyServiceKey } from '@imx/imgproxy-vue';
import type { ImgProxyService } from '@imx/imgproxy-vue';

export default defineNuxtPlugin((nuxtApp) => {
  const imgProxyService: ImgProxyService = createImageProxyService();
  nuxtApp.vueApp.provide(ImgProxyServiceKey, imgProxyService);

  // inject a globally available $imgproxy() method
  return {
    provide: {
      imgproxy: (
        source: string,
        size: string,
        focalX?: number,
        focalY?: number,
        cacheBuster?: string
      ): string => {
        return imgProxyService.buildImageUrl(
          source,
          size,
          focalX,
          focalY,
          cacheBuster
        );
      },
    },
  };
});

import type { WhitelabelAppConfig } from './models/WhitelabelAppConfig';
import { WhitelabelAppType } from './models/WhitelabelAppType';

export default defineAppConfig<WhitelabelAppConfig>({
  appType: WhitelabelAppType.PORTAL,
  icon: {
    mode: 'svg',
  },
});

declare module '@nuxt/schema' {
  interface AppConfigInput {
    appType: WhitelabelAppType;
    defaultLanguage?: string;
  }
}

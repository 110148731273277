import { default as bookmarksjqTaTrwToGMeta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/bookmarks.vue?macro=true";
import { default as _91permalink_93NgzrQOEKCiMeta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/events/detail/[permalink].vue?macro=true";
import { default as indexZK0Lu0Uf08Meta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/events/index.ts?macro=true";
import { default as listMaI9ucoWb0Meta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/events/list.vue?macro=true";
import { default as overviewPHnHCniT6WMeta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/events/overview.vue?macro=true";
import { default as indexuCUBj5qwRkMeta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/index.ts?macro=true";
import { default as _91permalink_934teNjybsP4Meta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/pois/detail/[permalink].vue?macro=true";
import { default as index7D5gkLUFjYMeta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/pois/index.ts?macro=true";
import { default as listED5OkfOojmMeta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/pois/list.vue?macro=true";
import { default as overviewXS3ukqQx1nMeta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/pois/overview.vue?macro=true";
import { default as _91permalink_93L4p1YdqXJDMeta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/tours/detail/[permalink].vue?macro=true";
import { default as indexkWm7eEsWakMeta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/tours/index.ts?macro=true";
import { default as listql1yqtyyBnMeta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/tours/list.vue?macro=true";
import { default as overview9cxqaL6VmHMeta } from "/builds/product/whitelabel/whitelabel/pages/[identifier]/tours/overview.vue?macro=true";
import { default as index49Vdf3cBNNMeta } from "/builds/product/whitelabel/whitelabel/pages/index.ts?macro=true";
export default [
  {
    name: "identifier-bookmarks___de",
    path: "/:identifier()/bookmarks",
    meta: bookmarksjqTaTrwToGMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/bookmarks.vue")
  },
  {
    name: "identifier-bookmarks___en",
    path: "/en/:identifier()/bookmarks",
    meta: bookmarksjqTaTrwToGMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/bookmarks.vue")
  },
  {
    name: "identifier-events-detail-permalink___de",
    path: "/:identifier()/events/detail/:permalink()",
    meta: _91permalink_93NgzrQOEKCiMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/events/detail/[permalink].vue")
  },
  {
    name: "identifier-events-detail-permalink___en",
    path: "/en/:identifier()/events/detail/:permalink()",
    meta: _91permalink_93NgzrQOEKCiMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/events/detail/[permalink].vue")
  },
  {
    name: "identifier-events___de",
    path: "/:identifier()/events",
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/events/index.ts")
  },
  {
    name: "identifier-events___en",
    path: "/en/:identifier()/events",
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/events/index.ts")
  },
  {
    name: "identifier-events-list___de",
    path: "/:identifier()/events/list",
    meta: listMaI9ucoWb0Meta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/events/list.vue")
  },
  {
    name: "identifier-events-list___en",
    path: "/en/:identifier()/events/list",
    meta: listMaI9ucoWb0Meta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/events/list.vue")
  },
  {
    name: "identifier-events-overview___de",
    path: "/:identifier()/events/overview",
    meta: overviewPHnHCniT6WMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/events/overview.vue")
  },
  {
    name: "identifier-events-overview___en",
    path: "/en/:identifier()/events/overview",
    meta: overviewPHnHCniT6WMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/events/overview.vue")
  },
  {
    name: "identifier___de",
    path: "/:identifier()",
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/index.ts")
  },
  {
    name: "identifier___en",
    path: "/en/:identifier()",
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/index.ts")
  },
  {
    name: "identifier-pois-detail-permalink___de",
    path: "/:identifier()/pois/detail/:permalink()",
    meta: _91permalink_934teNjybsP4Meta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/pois/detail/[permalink].vue")
  },
  {
    name: "identifier-pois-detail-permalink___en",
    path: "/en/:identifier()/pois/detail/:permalink()",
    meta: _91permalink_934teNjybsP4Meta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/pois/detail/[permalink].vue")
  },
  {
    name: "identifier-pois___de",
    path: "/:identifier()/pois",
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/pois/index.ts")
  },
  {
    name: "identifier-pois___en",
    path: "/en/:identifier()/pois",
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/pois/index.ts")
  },
  {
    name: "identifier-pois-list___de",
    path: "/:identifier()/pois/list",
    meta: listED5OkfOojmMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/pois/list.vue")
  },
  {
    name: "identifier-pois-list___en",
    path: "/en/:identifier()/pois/list",
    meta: listED5OkfOojmMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/pois/list.vue")
  },
  {
    name: "identifier-pois-overview___de",
    path: "/:identifier()/pois/overview",
    meta: overviewXS3ukqQx1nMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/pois/overview.vue")
  },
  {
    name: "identifier-pois-overview___en",
    path: "/en/:identifier()/pois/overview",
    meta: overviewXS3ukqQx1nMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/pois/overview.vue")
  },
  {
    name: "identifier-tours-detail-permalink___de",
    path: "/:identifier()/tours/detail/:permalink()",
    meta: _91permalink_93L4p1YdqXJDMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/tours/detail/[permalink].vue")
  },
  {
    name: "identifier-tours-detail-permalink___en",
    path: "/en/:identifier()/tours/detail/:permalink()",
    meta: _91permalink_93L4p1YdqXJDMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/tours/detail/[permalink].vue")
  },
  {
    name: "identifier-tours___de",
    path: "/:identifier()/tours",
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/tours/index.ts")
  },
  {
    name: "identifier-tours___en",
    path: "/en/:identifier()/tours",
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/tours/index.ts")
  },
  {
    name: "identifier-tours-list___de",
    path: "/:identifier()/tours/list",
    meta: listql1yqtyyBnMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/tours/list.vue")
  },
  {
    name: "identifier-tours-list___en",
    path: "/en/:identifier()/tours/list",
    meta: listql1yqtyyBnMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/tours/list.vue")
  },
  {
    name: "identifier-tours-overview___de",
    path: "/:identifier()/tours/overview",
    meta: overview9cxqaL6VmHMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/tours/overview.vue")
  },
  {
    name: "identifier-tours-overview___en",
    path: "/en/:identifier()/tours/overview",
    meta: overview9cxqaL6VmHMeta || {},
    component: () => import("/builds/product/whitelabel/whitelabel/pages/[identifier]/tours/overview.vue")
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/builds/product/whitelabel/whitelabel/pages/index.ts")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/product/whitelabel/whitelabel/pages/index.ts")
  }
]
import { CONFIG as demo } from './instances/demo';
import { CONFIG as fwtm } from './instances/fwtm';
import { CONFIG as tash } from './instances/tash';
import type { BaseInstanceConfig } from './models/BaseInstanceConfig';

export type InstanceConfigMapType = Record<
  string,
  Omit<BaseInstanceConfig, 'instanceId'>
>;

export const INSTANCE_TO_CONFIGS: InstanceConfigMapType = {
  fwtm,
  tash,
  demo,
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('focus-first', {
    mounted(el) {
      requestAnimationFrame(() => {
        const focusableElements = el.querySelectorAll(
          'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
        );
        if (focusableElements.length > 0) {
          focusableElements[0].focus();
        }
      });
    },
  });
});

import type { MediaFilter, SortOrder } from '../gql/schema';
import type { Accessor } from './accessor';
import type { BaseAddress } from './BaseAddress';
import type { BaseCoords } from './BaseCoords';
import type { BaseTourDifficulty } from './BaseTourDifficulty';

export interface BaseApiMediaFilterArguments {
  filter?: MediaFilter;
  fallback?: MediaFilter[];
  sortOrder?: SortOrder;
}

export enum ContactType {
  CONTRIBUTOR = 'contributor',
  CONTACT = 'contact',
}

/** id of entity to iconify icon */
export type IconMapping = Record<number, string>;

/** value of tour difficulty to BaseTourDifficulty */
export type TourDifficultyMapping = Record<number, BaseTourDifficulty>;

export type OpeningHoursConfiguration = {
  hideDateRangeInfo?: boolean;
};

export interface BaseObjectTypeConfiguration {
  hero?: BaseApiMediaFilterArguments;
  gallery?: BaseApiMediaFilterArguments;
  list?: BaseApiMediaFilterArguments;
  article?: {
    contact?: ContactType;
  };
}

export interface BasePoiTypeConfiguration extends BaseObjectTypeConfiguration {
  /**
   * Icon mapping for productlines (id => iconify icon)
   */
  productlineIcons?: IconMapping;
  openingHours?: OpeningHoursConfiguration;
}

export interface BaseTourTypeConfiguration extends BaseObjectTypeConfiguration {
  /**
   * Icon mapping for the tour categories (id => iconify icon)
   */
  categoryIcons?: IconMapping;
  difficultyMapping?: TourDifficultyMapping;
}

export interface MapInstanceConfiguration {
  /**
   * default coordinates when the map is initially loaded
   */
  defaultCoords: BaseCoords;

  /**
   * default map zoom level when the map is initially loaded. Default: 12
   */
  defaultZoom?: number;

  /**
   * Whether to show the weather on the map. Default: false
   */
  weatherControls?: boolean;
}

export interface RoutingConfiguration {
  /**
   * Define which routing service to use for public transport routing. Default: Google Maps
   */
  publicTransportRoutingService: RoutingService;

  /**
   * Define which routing service to use for car routing. Default: Google Maps
   */
  carRoutingService: RoutingService;

  /**
   * Define the purpose id of contact addressbases, representing the next public transport station.
   */
  publicTransportStationContactPurposeId?: number;
  publicTransportBusProductlineIds?: number[];
  publicTransportTrainProductlineIds?: number[];
}

export enum RoutingService {
  GOOGLE_MAPS,
  NAHSH,
}

export interface BaseInstanceConfig {
  /** The ID of the imx.Platform (Whitelabel Widgets) instance. */
  instanceId: string;

  /**
   * The URL of the GraphQL endpoint.
   */
  graphqlEndpoint: string;

  /**
   * The bearer token to use for authentication.
   */
  graphqlBearerToken: string;

  /**
   * The default widget id to use when no widget identifier is provided.
   */
  defaultWidgetId: number;

  /**
   * The languages that are enabled for this instance.
   */
  enabledLanguages: string[];

  /**
   * The alias to use for branding. If not provided, instanceId is used to access branding.
   */
  brandingAlias?: string;

  /**
   * The URL to the logo to use in the header.
   */
  headerLogo?: string;

  /**
   * The URL to the logo to use in the footer.
   */
  footerLogo?: string;

  /**
   * If true, use instance specific fallback images. If false, the default fallback images are used.
   *
   * Default: false
   */
  customFallbackImages?: boolean;

  /** The contact information to display in the footer. */
  footerContact: Required<BaseAddress>;

  /**
   * The default language to use when no language is provided.
   */
  map: MapInstanceConfiguration;

  /**
   * Configuration for the routing.
   */
  routing?: RoutingConfiguration;

  /**
   * Default configuration for all object types.
   */
  defaults: BaseObjectTypeConfiguration;

  /**
   * Configuration for the event object type.
   */
  event?: BaseObjectTypeConfiguration;

  /**
   * Configuration for the poi object type.
   */
  poi?: BasePoiTypeConfiguration;

  /**
   * Configuration for the tour object type.
   */
  tour?: BaseTourTypeConfiguration;
}

export type BaseInstanceConfigAccessor = Accessor<BaseInstanceConfig>;

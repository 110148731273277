<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="css">
/* beware: transition styles must be global and do not work, if they are scoped */

.page-enter-active,
.page-leave-active {
  transition: all 70ms;
}
.page-enter-from,
.page-leave-to {
  opacity: 0.1;
}
</style>
